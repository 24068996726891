import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`We will reopen for class at 10:30 today.  All other classes are back
to normal for the rest of the day.`}</strong></p>
    <p>{`Back Squat 4×6\\@80% 1RM`}</p>
    <p>{`Shoulder Bridge 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`20-Pistols (10/leg, alternating legs)`}</p>
    <p>{`20-GHD Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our FREE New Years Day workout will be at 10:30.  Bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      